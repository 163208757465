import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { ReactComponent as WorkIcon } from "../../logos/work.svg";

import TimelineEvents from "../../data/TimelineEvents/TimelineEvents";

function Experience() {
  return (
    <div className="flex flex-col min-h-screen bg-gray-300">
      <div>
        <Header>Experience</Header>
      </div>
      <div className="flex-grow m-[10vw]">
        <VerticalTimeline>
          {TimelineEvents.map((element) => {
            return (
              <VerticalTimelineElement
                key={element.id}
                date={element.date}
                icon={<WorkIcon className="fill-white" />}
                dateClassName="xl:text-dark-blue text-white font-custom"
                contentStyle={{ background: "#001A42", color: "white" }}
                iconStyle={{ background: "#001A42", color: "white" }}
                contentArrowStyle={{ borderRight: "7px solid #001A42" }}
              >
                <div className="text-xssm font-custom font-extrabold">
                  {element.title}
                </div>

                <div className="text-xs font-custom italic">
                  {element.location}
                </div>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Experience;
