import React from 'react'
import NavBar from '../NavBar';

function Header({ children })  {
  return (
    <div className="flex flex-col items-center w-full min-h-[25vh] bg-dark-blue pt-5vh">
      <NavBar/>
      <div className='xl:text-xlg lg:text-lg md:text-md sm:text-sm xs:text-xs font-custom uppercase text-white pb-[5vh]'>{children}</div>
    </div>
  )
}

export default Header;