import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MyImage from "../../images/qasim.jpg";

function Homepage() {
  return (
    <div className="flex flex-col min-h-screen bg-gray-300">
      <div>
        <Header>Qasim Bhutta</Header>
      </div>
      <div className="flex flex-row flex-grow items-center justify-center gap-[5vw] bg-gray-300">
        <div className="max-w-[35vw]">
          <p className="mt-[5vh] font-extrabold lg:text-md md:text-sm sm:text-sm text-black font-custom break-normal">
            Hi, I'm <span className="text-dark-blue">Qasim</span>,
          </p>
          <p className="mt-[1vh] font-bold lg:text-xssm md:text-xs sm:text-xs xs:text-xs text-black font-custom break-normal">
            a Computer Scientist eager to learn and apply my skills in tech. I
            am well&#8209;versed in many languages, frameworks, and
            technologies. I am mainly interested in FinTech, AI & ML, and Cloud
            Computing. I am open to opportunities so I can apply my skills in
            these fields and bring my unique perspective to the team and
            company. Explore this website to learn about my professional
            journey. Feel free to connect with me on LinkedIn for professional
            networking and opportunities. I'm open to hearing about job offers
            and collaborations!{" "}
          </p>
        </div>
      </div>
      <div className="mt-[1vh] flex justify-center bg-gray-300"></div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Homepage;
