import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import VisualStudioIcon from "../../logos/visual_studio.svg"
import HTMLIcon from "../../logos/html.svg"
import CSSIcon from "../../logos/css.svg"
import JavaScriptIcon from "../../logos/javascript.svg"
import PythonIcon from "../../logos/python.svg"
import JavaIcon from "../../logos/java.svg"
import CPPIcon from "../../logos/c++.svg"
import ReactIcon from "../../logos/react.svg"
import NodeIcon from "../../logos/nodejs.svg"
import ExpressIcon from "../../logos/express.svg"
import MySQLIcon from "../../logos/mysql.svg"
import MSSIcon from "../../logos/mss.svg"
import MongoDBIcon from "../../logos/mongodb.svg"
import TailwindIcon from "../../logos/tailwind.svg"
import GitIcon from "../../logos/git.svg"
import GitHubIcon from "../../logos/github-black.svg"
import PostmanIcon from "../../logos/postman.svg"
import AWSIcon from "../../logos/aws.svg"
import LinuxIcon from "../../logos/linux.svg"
import WindowsIcon from "../../logos/windows.svg"
import MacOSIcon from "../../logos/macos.svg"
import JetBrainsIcon from "../../logos/jetbrains.svg"
import VSCodeIcon from "../../logos/vscode.svg"
import ShellIcon from "../../logos/cli-bash.svg"
import NetlifyIcon from "../../logos/netlify.svg"
import SSHIcon from "../../logos/ssh.svg"
import NotepadPPIcon from "../../logos/notepadpp.svg"
import JupyterIcon from "../../logos/jupyter.svg"
import CIcon from "../../logos/c.svg"
import FigmaIcon from "../../logos/figma.svg"


function Skills()
{
    return(
        <div className="flex flex-col min-h-screen bg-gray-300">
        <div>    
            <Header>Skills</Header>
        </div>
        <div className="flex-grow m-[15vw]">
            <div className="grid xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2 gap-24">
                <div className="group relative w-full h-full">
                    <img src={HTMLIcon} alt="HTML 5" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">HTML</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={CSSIcon} alt="CSS" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">CSS</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={JavaScriptIcon} alt="JavaScript" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">JavaScript</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={ReactIcon} alt="React" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">React.js</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={ExpressIcon} alt="Express.js" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">Express.js</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={NodeIcon} alt="Node.js" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">Node.js</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={CIcon} alt="C" className="object-fill group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">C</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={CPPIcon} alt="C++" className="object-fill group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">C++</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={JavaIcon} alt="Java" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">Java</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={PythonIcon} alt="Python" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">Python</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={MySQLIcon} alt="MySQL" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">MySQL</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={MSSIcon} alt="Microsoft SQL Server" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Microsoft SQL Server</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={MongoDBIcon} alt="MongoDB" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">MongoDB</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={TailwindIcon} alt="Tailwind CSS" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Tailwind CSS</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={GitIcon} alt="Git" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">Git</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={GitHubIcon} alt="GitHub" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">GitHub</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={PostmanIcon} alt="Postman" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">Postman</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={AWSIcon} alt="AWS" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm">AWS</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={NetlifyIcon} alt="Netlify" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Netlify</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={WindowsIcon} alt="Microsoft Windows" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Microsoft Windows</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={LinuxIcon} alt="Linux" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Linux</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={MacOSIcon} alt="Mac OS" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Mac OS</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={VisualStudioIcon} alt="Visual Studio" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Visual Studio</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={VSCodeIcon} alt="Visual Studio Code" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Visual Studio Code</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={JetBrainsIcon} alt="JetBrains" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">JetBrains (IntelliJ | PyCharm)</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={ShellIcon} alt="Shell-CLI-Bash" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Shell (CLI/Bash)</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={SSHIcon} alt="SSH" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Secure Shell (PuTTY + MobaXTerm)</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={JupyterIcon} alt="Jupyter Notebooks" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Jupyter Notebooks</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={NotepadPPIcon} alt="Notepad++" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Notepad++</span>
                </div>
                <div className="group relative w-full h-full">
                    <img src={FigmaIcon} alt="Figma" className="object-fill w-full h-full group-hover:hidden"/>
                    <span className="hidden group-hover:flex justify-center items-center w-full h-full font-custom text-sm text-center">Figma</span>
                </div>
            </div>
        </div>
        <div>
            <Footer/>
        </div>
    </div>
    )
}

export default Skills;
