import React from "react";
import GitHub from "../../logos/github.svg";
import LinkedIn from "../../logos/linkedin.svg";

function Footer() {
  return (
    <div className="flex w-full min-h-[10vh] max-h-[10vh] bg-dark-blue justify-between items-center">
      <div className="flex flex-wrap font-custom text-xs text-white ml-10vw">
        © Qasim Bhutta 2024. Designed from scratch using React and Tailwind.
        Hosted on Netlify.
      </div>
      <div className="flex justify-center items-center mt-[5px] mr-10vw gap-5">
        <a href="https://github.com/qasimb03" target="_blank" rel="noreferrer">
          <img
            src={GitHub}
            alt="GitHub Logo"
            className="lg:w-[65px] lg:h-[65px] md:w-[50px] md:h-[50px] sm:w-[50px] sm:h-[50px] xs:w-[20px] xs:h-[20px]"
          />
        </a>
        <a
          href="https://linkedin.com/in/qasim-bhutta"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={LinkedIn}
            alt="LinkedIn Logo"
            className="lg:w-[65px] lg:h-[65px] md:w-[50px] md:h-[50px] sm:w-[50px] sm:h-[50px] xs:w-[20px] xs:h-[20px]"
          />
        </a>
      </div>
    </div>
  );
}

export default Footer;
