import React from "react";
import Resume from "../data/Resume/Qasim_Bhutta_Resume.pdf";

const NavBar = () => {
  return (
    <nav className="flex flex-wrap justify-center gap-5 text-white lg:text-sm md:text-sm sm:text-xs font-custom mb-8">
      <a href="/">Home</a>
      <a href="/skills">Skills</a>
      <a href="/experience">Experience</a>
      <a href="/projects">Projects</a>
      <a href={Resume} target="_blank" rel="noopener noreferrer">
        Resume
      </a>
      <a href="/pop">POP</a>
    </nav>
  );
};

export default NavBar;
