let timelineEvents = [
  {
    id: 1,
    title: "Software Engineer Intern",
    location: "Verizon Communications",
    date: "June 2024 - August 2024",
    description: [
      "Pioneered a network device auditing tool that increased missing device exploration by 75% and reduced exploration time by 85% through an interactive application created using Python, Django, MySQL, and React.js.",
      "Collaborated with several teams to identify and gather necessary information required to audit over 20,000 devices.",
      "Presented a comprehensive dashboard showcasing key metrics and business impact to over 50 senior executives and team representatives within the company.",
      "Acquired advocation for application to be placed into production, leading to data-driven decision-making, and fostering company-wide performance improvements.",
      "Deployed server-side scripts on Linux Virtual Machines to run audit program daily.",
    ],
  },
  {
    id: 2,
    title: "Software Engineer Intern",
    location: "Verizon Communications",
    date: "June 2023 - August 2023",
    description: [
      "Pioneered a network device auditing tool that increased missing device exploration by 75% and reduced exploration time by 85% through an interactive application created using Python, Django, MySQL, and React.js.",
      "Collaborated with several teams to identify and gather necessary information required to audit over 20,000 devices.",
      "Presented a comprehensive dashboard showcasing key metrics and business impact to over 50 senior executives and team representatives within the company.",
      "Acquired advocation for application to be placed into production, leading to data-driven decision-making, and fostering company-wide performance improvements.",
      "Deployed server-side scripts on Linux Virtual Machines to run audit program daily.",
    ],
  },
  {
    id: 3,
    title: "Technical Lead",
    location: "iCode School Franchise",
    date: "August 2021 - May 2022",
    description: [
      "Pioneered a network device auditing tool that increased missing device exploration by 75% and reduced exploration time by 85% through an interactive application created using Python, Django, MySQL, and React.js.",
      "Collaborated with several teams to identify and gather necessary information required to audit over 20,000 devices.",
      "Presented a comprehensive dashboard showcasing key metrics and business impact to over 50 senior executives and team representatives within the company.",
      "Acquired advocation for application to be placed into production, leading to data-driven decision-making, and fostering company-wide performance improvements.",
      "Deployed server-side scripts on Linux Virtual Machines to run audit program daily.",
    ],
  },
];

export default timelineEvents;
