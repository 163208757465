import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactForm from "../../components/ContactForm/ContactForm";

function Contact() {
  return (
    <div className="flex flex-col min-h-screen bg-gray-300">
      <div>
        <Header>Contact</Header>
      </div>
      <div className="flex flex-grow ml-[25vw] mr-[25vw] mt-[10vh] mb-[10vh] justify-center">
        <ContactForm />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Contact;
